<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.edit')} ${$tc('models.module', 1)}`"
  >
    <course-module-form
      v-if="courseModule"
      :courseModule="courseModule"
      :invalid="invalid"
      :invalidNo="invalidNo"
      :invalidEn="invalidEn"
      @submit="handleSubmit"
      @cancel="handleCancel"
    >
    </course-module-form>
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import CourseModuleForm from '../components/CourseModuleForm';
import { getModule, updateModule } from '../api';

export default {
  data() {
    return {
      courseModule: null,
      loading: false
    };
  },

  components: {
    UiCard,
    CourseModuleForm
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        const courseModule = await updateModule(this.courseModule);
        this.$router.push({
          name: 'courses-show',
          params: { id: courseModule.course.id }
        });
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    async getCourseModule(id) {
      try {
        this.courseModule = await getModule(id);
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.back();
    }
  },

  computed: {
    invalidNoName() {
      const prop = this.courseModule.name.filter(t => t.lang === 'no')[0];
      return prop.value === '' ? 1 : 0;
    },

    invalidNoDescr() {
      const prop = this.courseModule.description.filter(
        t => t.lang === 'no'
      )[0];
      return prop.value === '' || prop.value === '<p></p>' ? 1 : 0;
    },

    invalidNo() {
      return this.invalidNoName + this.invalidNoDescr;
    },

    invalidEnName() {
      const prop = this.courseModule.name.filter(t => t.lang === 'en')[0];
      return prop.value === '' ? 1 : 0;
    },

    invalidEnDescr() {
      const prop = this.courseModule.description.filter(
        t => t.lang === 'en'
      )[0];
      return prop.value === '' || prop.value === '<p></p>' ? 1 : 0;
    },

    invalidEn() {
      return this.invalidEnName + this.invalidEnDescr;
    },

    invalid() {
      return this.invalidNo + this.invalidEn;
    }
  },

  created() {
    this.getCourseModule(this.$router.history.current.params.courseModuleId);
  }
};
</script>
